




























































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Fraction } from '@/helper/helperIndex';
import { RGB, ColorName, ColorLists, ColorList, GetRGBList } from '@/helper/Colors';
import { Lumen, Ecw, Mazcon } from '@/helper/Text';

@Component({
    components: {
        TitleEffect: () => import('@/components/utils/TitleEffect.vue'),
        Card: () => import('@/components/utils/Card.vue'),
    },
})
export default class WorkLook extends Vue {
    color: ColorList = ColorLists[0];
    starter: number = 1230;
    scrollVariable: number = 0;
    difference: number = 350;
    lumen: string = Lumen;
    ecw: string = Ecw;
    mazcon: string = Mazcon;
    tempDescription: string = `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ratione consequuntur
                                perspiciatis at repudiandae architecto rerum similique rem velit minima.
                                Voluptates ipsam id, laudantium molestias numquam doloribus accusamus ad quos
                                odit!`;

    onScroll(event: any) {
        this.scrollVariable = event.target.scrollTop;
    }

    cardMovement(serialNo: number) {
        const difference: number = 400;
        return this.starter + serialNo * difference;
    }

    // radialEffect(start: number, end: number) {
    //     let fraction = Fraction(start, end, this.scrollVariable);
    //     let hex = this.color.hex;
    //     return `radial-gradient(circle at ${200 - fraction * 100}%, rgba(${hex}, 0.527) 5%, #eee 35%, #121212 35%)`;
    // }
}
