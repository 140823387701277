var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"work-info"},[_c('div',{staticClass:"work-container"},[_c('v-row',{directives:[{name:"scroll",rawName:"v-scroll:#scroll-target",value:(_vm.onScroll),expression:"onScroll",arg:"#scroll-target"}],staticClass:"title-row",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('TitleEffect',{attrs:{"scrollVariable":_vm.scrollVariable,"title":'Work',"effectEnd":_vm.starter,"difference":600,"primaryColorHex":_vm.color.hex}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"card-col",attrs:{"cols":"auto","align":"right"}},[_c('Card',{attrs:{"isDirectionLeft":true,"effectEnd":_vm.cardMovement(1),"difference":350,"jobDetails":{
                        jobTitle: 'Network Visibility Dashboard',
                        description: _vm.lumen,
                        logo: "lumen",
                    }}})],1),_c('v-col',{staticClass:"card-col",attrs:{"cols":"auto"}}),_c('v-col',{staticClass:"card-col",attrs:{"cols":"auto"}}),_c('v-col',{staticClass:"card-col",attrs:{"align":"left","cols":"auto"}},[_c('Card',{attrs:{"isDirectionLeft":false,"effectEnd":_vm.cardMovement(2),"difference":350,"jobDetails":{
                        jobTitle: 'MIPS-MACRA & HRMS',
                        description: _vm.ecw,
                        logo: "eCW",
                    }}})],1),_c('v-col',{staticClass:"card-col",attrs:{"cols":"auto","align":"right"}},[_c('Card',{attrs:{"isDirectionLeft":true,"effectEnd":_vm.cardMovement(3),"difference":350,"jobDetails":{
                        jobTitle: 'Employee Attendance & Performance Tracker',
                        description: _vm.mazcon,
                        logo: "mazcon",
                    }}})],1),_c('v-col',{staticClass:"card-col",attrs:{"cols":"auto"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }