export const Lumen = `I am helping Lumen Technologies to develop Network Visibility Dashboard (NVD) on Vue + TypeScript framework. The current Beta version of this app is being loved and used by 54.3K users. NVD is the one-stop solution for Lumen's enterprice level customers like Google, Wallmart, StarBucks, Bloomburg (and many more), providing them solutions to every aspect of Lumen's network service. This app is lightweight, easy to use, with complex computations on data shown with Google-Maps Library, Chart.JS, and large descriptive matrix.`;

export const Ecw = `At eClinical Works, I worked on the front end of the HRMS web app used by internal customers. This app was developed using the React framework. I helped them to set up app routing, features like Leave Application & TimeSheet. Additionally, I worked also on the backend of their top of the line app's MIP-MACRA feature that would help Practitioners for filing Medicare and Medicaid claims`;

export const Mazcon = `I worked here on a React framework to develop an Employee Attendance and Performance Tracker application, an end product for companies that can use it as Timesheet and daily performance tracking for companies. This app was developed for desktop as well as mobile for ease of use. I also developed the backend using Java and SQL.`;

export const Snakes = `I came across an idea that Snakes Game can also be developed using a Linked-List data structure (after the snake itself looks like a linked-list), and so I decided to develop an app in Vue + TypeScript framework with Vuetify(material design) to help with design patterns. Apart from the linked-list, this app also uses hashtable as a data structure, which makes the run-time complexity of this app optimal. This app has the ability to store your score in the browser that you are using and you can also change the speed of the snake so that the game can be competitive and fun.`;

export const Revent = `Revent was a practice project to learn what's latest and greatest with React JS. This is a social media web app that has major similar features as Facebook - posts, chat, storyline, profile, profile-picture, events, etc. This app had a backend with Google's FireStrone. Proper asynchronous calls to API made this application faster and east to use.`;

export const Organic = `I developed this app with the aim to learn about Angular (v6). This was an eCommerce website with similar features to Amazon, like - cards to display products, shopping cart (display product in your cart), checkout, oAuth login, non-login use of the app (log-in only required when checking out). OrganicBar is a scalable, fast, and easy-to-use app.`;

export const Capstone = `This was a Capstone Project with Massachusett Audubon society. The aim of the project was to provide a solution to organize and possibly display graphically, their 10 years old customer data (totaling up to 5GB of raw excel data). As a solution, we cleaned the data, migrated it to an SQL database, and developed an Angular app that would help them input data in the form formate. We also used chart.js for visualizing the data in charts format. Alternatively, we also provided a visualization option with Tableau. Using Tableau they can see the data on maps to see the hotspots. This project was going to help Mass. Audubon to get the future project to conserve the natural sanctuary from the government. This Capstone Project was a huge success and was awarded 1st prize for that semester.`;

export const Splunk = `This was a class project during the master's program where we used Splunk SDK to display login activities of the Spotify App. We injected the Sportify data into Splunk, provided appropriate buckets to each data and used the D3 visualization library to display the result.`;

export const Hotel = `This was a very small project, but it is very close to me since my interests in Frontend Development spiked due to this project. This was the time when the FrontEnd Engineering journey begin. This was a class project where we had to develop a single page HTML + CSS + Javascript application. My topic was 'Online Hotel Booking', and I remember how thrilled I was to develop this single-page website.`;
